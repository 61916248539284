// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as ReactHelmet from "react-helmet";

import  {graphql}  from "gatsby"
    import 'normalize.css'
    import './layout.css'
    import './fonts.css'
    import './text.css'
;

var meta = [
  /* MetaWithName */Block.__(0, [
      "description",
      "A playful avatar generator for the modern age."
    ]),
  /* MetaWithName */Block.__(0, [
      "twitter:card",
      "summary"
    ]),
  /* MetaWithName */Block.__(0, [
      "twitter:site",
      "@draftbit"
    ]),
  /* MetaWithName */Block.__(0, [
      "twitter:title",
      "Personas by Draftbit"
    ]),
  /* MetaWithName */Block.__(0, [
      "twitter:image",
      "https://personas.draftbit.com/web-preview-1x1.png"
    ]),
  /* MetaWithName */Block.__(0, [
      "twitter:description",
      "A playful avatar generator for the modern age."
    ]),
  /* MetaWithName */Block.__(0, [
      "keywords",
      "Avatar, Avatar Generator, Personas, Draftbit, Avatars"
    ]),
  /* MetaWithProperty */Block.__(1, [
      "fb:app_id",
      "292869531545861"
    ]),
  /* MetaWithProperty */Block.__(1, [
      "og:type",
      "website"
    ]),
  /* MetaWithProperty */Block.__(1, [
      "og:image",
      "https://personas.draftbit.com/web-preview.png"
    ]),
  /* MetaWithProperty */Block.__(1, [
      "og:title",
      "Personas by Draftbit"
    ]),
  /* MetaWithProperty */Block.__(1, [
      "og:url",
      "https://personas.draftbit.com"
    ]),
  /* MetaWithProperty */Block.__(1, [
      "og:description",
      "A playful avatar generator for the modern age."
    ])
];

var link = [{
    rel: "canonical",
    href: "https://personas.draftbit.com"
  }];

function metaToHelmet(item) {
  if (item.tag) {
    return {
            property: item[0],
            content: item[1]
          };
  } else {
    return {
            name: item[0],
            content: item[1]
          };
  }
}

function Layout(Props) {
  var children = Props.children;
  var data = Gatsby.useStaticQuery((graphql`
                    query SiteTitleQuery {
                      site {
                        siteMetadata {
                          title
                        }
                      }
                    }
                  `));
  return React.createElement(React.Fragment, undefined, React.createElement(ReactHelmet.Helmet, {
                  link: link,
                  meta: $$Array.map(metaToHelmet, meta),
                  title: data.site.siteMetadata.title,
                  children: React.createElement("html", {
                        lang: "en"
                      })
                }), children);
}

var make = Layout;

var $$default = Layout;

export {
  meta ,
  link ,
  metaToHelmet ,
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
